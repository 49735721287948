import { useCallback, useState } from 'react';

const usePhotoLoader = () => {
  const [photoBlob, setPhotoBlob] = useState(null);
  const loadPhoto = useCallback((event) => {
    const input = event.target;
    const imageFiles = Array.from(event.target.files);
    const imageFile = imageFiles[0]

    setTimeout(() => {
      setPhotoBlob(imageFile);
      input.value = "";
    }, 200);
    input.removeEventListener('change', loadPhoto);
  }, [])

  const openFileSelector = useCallback((type) => {
    const input = document.querySelector('#file-loader-input');
    if (type === 'webcam') {
      input.capture = "user"
    } else {
      input.removeAttribute('capture');
    }

    input.addEventListener('change', loadPhoto);
    setTimeout(() => {
      input.click();
    }, 200)

  }, [loadPhoto])
  return { openFileSelector, photoBlob };
};

export default usePhotoLoader;

import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Result } from "../components/Result/ResultPage";
import { Loader } from "../components/Loader";
import { convertImageUrl, isTelegram, loadCharacter, loadResultScore } from "../utils";
import { useHistory } from "react-router-dom/";
import { usePaywallMethod, useSetPaywallMethod, useShowError, useTimerDelay } from "../context";
import { getUdid } from "../utils/request";

export const ResultPage = () => {

    const history = useHistory();

    let { photoId } = useParams();
    const alreadyLoading = useRef()
    const [isLoading, setIsLoading] = useState(true);
    const [isCharacterLoading, setIsCharacterLoading] = useState(true);
    const [characterCaption, setCharacterCaption] = useState('');
    const [characterUrl, setCharacterUrl] = useState('');
    const [result, setResult] = useState(null)
    const paywallMethod = usePaywallMethod();
    const [progress, setProgress] = useState('');
    const showError = useShowError();

    const [characterProgress, setCharacterProgress] = useState('')
    const setPaywallMethod = useSetPaywallMethod();

    const [timeoutFallenFetch, setTimeoutFallenFetch] = useState(false);
    const timerDelay = useTimerDelay();

    const setSelectedTimeout = useCallback(()=>{
        setTimeoutFallenFetch(true)
    }, [])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
    }, [])

    useEffect(() => {
        if (result?.photo) {
            const description = document.querySelector(`meta[property='og:description']`);
            description.setAttribute('content', 'Check Out How the AI Rates Me!');

            const imageUrlMeta = document.querySelector(`meta[property='og:image']`);
            imageUrlMeta.setAttribute('content', result?.photo);

        }
    }, [result?.photo])

    useEffect(() => {
        if (photoId && paywallMethod) {
            if (alreadyLoading.current === true) {
                return;
            }
            alreadyLoading.current = true;
            loadResultScore(photoId, paywallMethod, setProgress).then((result) => {
                if (result.data.status) {
                    setResult(result.data.result)
                    setCharacterCaption(result.data.result.character.caption || '')
                    setIsLoading(false)
                    alreadyLoading.current = false;

                    if (result.data.result.character.url) {
                        setIsCharacterLoading(false);
                        setCharacterUrl(result.data.result.character.url);

                    } else {
                        loadCharacter(photoId, setCharacterProgress, setSelectedTimeout, timerDelay).then((result,) => {
                            if (!result) {
                                return;
                            }
                            if (result?.data?.status) {
                                setIsCharacterLoading(false);
                                setCharacterUrl(result.data.result.url);
                                setPaywallMethod('none');
                            } else {
                                showError(result.data.message || 'No Internet Connection');
                                console.error('error', result);
                                history.push('/err');
                            }
                        })
                    }

                } else {
                    console.error('error', result.data)
                    showError(result.data.message || 'No Internet Connection');
                    history.push('/err');
                }
            }).catch((e) => {
                console.error('result err', e)
                history.push('/err');
                setIsLoading(false);
            })
        }
    }, [photoId, history, paywallMethod, setPaywallMethod, showError, timerDelay, setSelectedTimeout])

    if (!isLoading && !result) {
        return <div>error loading</div>
    }

    return <>
        {isLoading ? <div>
            <Loader progress={progress} />
        </div> : <Result
            timeoutFallenFetch={timeoutFallenFetch}
            characterProgress={characterProgress}
            characterUrl={characterUrl}
            characterCaption={characterCaption}
            isCharacterLoading={isCharacterLoading}
            photoSrc={result.photo}
            score={result.score}
            status={result.status}
            reviews={result.reviews}
            isAuthorSession={result.udid === getUdid()}
            photoId={photoId}
        />}
    </>
}

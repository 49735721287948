import { useCallback } from "react";
import { FacebookIcon, InstagramIcon, SnapchatIcon, TikTokIcon, TwitterIcon, } from "../../icons"

import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { makeRequest } from "../../utils/request";


export const SharingBlock = ({ characterUrl }) => {

    const shareClicked = useCallback((media) => {
        makeRequest('/api/user/update', 'post', {
            tap: media
        });
    }, [])

    const notImplementedShared = useCallback((media) => {
        alert(`This method of sharing isn't implemented yet`);
        shareClicked(media);
    }, [shareClicked])

    return (
        <div className="flex mt-[16px] gap-[12px]">
            <ShareButton type="instagram" onClick={notImplementedShared} characterUrl={characterUrl} />
            <ShareButton type="facebook" onClick={shareClicked} characterUrl={characterUrl} />
            <ShareButton type="snapchat" onClick={notImplementedShared} characterUrl={characterUrl} />
            <ShareButton type="twitter" onClick={shareClicked} characterUrl={characterUrl} />
            <ShareButton type="tiktok" onClick={notImplementedShared} characterUrl={characterUrl} />
        </div>
    )
}

const ShareButton = ({ type, characterUrl, onClick }) => {

    const onShareClick = useCallback(() => {
        onClick(type)
    }, [onClick, type]);

    switch (type) {

        case 'tiktok':
            return (
                <button onClick={onShareClick} className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                    <TikTokIcon />
                </button>
            );

        case 'instagram':
            return (
                <button onClick={onShareClick} className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                    <InstagramIcon />
                </button>
            );
        case 'facebook':
            return (
                <FacebookShareButton onClick={onShareClick} url={window.location.href}>
                    <div className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                        <FacebookIcon />
                    </div>
                </FacebookShareButton>
            );
        case 'whatsapp':
            return (
                <WhatsappShareButton onClick={onShareClick} url={window.location.href} >
                    <div className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                        {/* <WhatsAppIcon /> */}
                    </div>

                </WhatsappShareButton>
            );
        case 'snapchat':
            return (
                <button onClick={onShareClick} className="snapchat-share-button bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                    <SnapchatIcon />
                </button>
            );
        case 'twitter':
            return (
                <TwitterShareButton onClick={onShareClick} url={window.location.href}>
                    <div className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                        <TwitterIcon />
                    </div>
                </TwitterShareButton>
            );
        case 'pinterest':
            return (
                <PinterestShareButton onClick={onShareClick} disabled={!characterUrl} description="Image" media={characterUrl} url={window.location.href}>
                    <div className="bg-[#242a46] items-center justify-center rounded-[50%] flex w-[48px] h-[48px]" >
                        {/* <PinterestIcon /> */}
                    </div>
                </PinterestShareButton>
            );
        default:
            return null;
    }
}
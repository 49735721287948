import { useCallback, useEffect, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { makeRequest } from "../utils/request";

export const AppContext = createContext({
    errorText: '',
    isErrorShown: false,
    photoSrc: '',
})

AppContext.displayName = 'AppContext'


export const AppContextProvider = ({ children }) => {
    const [errorText, setErrorText] = useState('');
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [photoSrc, setPhotoSrc] = useState('')
    const [paywallMethod, setPaywallMethod] = useState('none');
    const [shouldShowPaywall, setShowPaywall] = useState(true);
    const [timeoutDelay, setTimeoutDelay] = useState(45);
    const [allowedNotifications, setAllowedNotifications] = useState(false);
    const [allowedPhotoShow, setAllowPhotoShow] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const utm_medium = url.searchParams.get("utm_medium") || undefined;
        const utm_source = url.searchParams.get("utm_source") || undefined;
        const utm_campaign = url.searchParams.get("utm_campaign") || undefined;
        const utm_term = url.searchParams.get("utm_term") || undefined;
        const utm_content = url.searchParams.get("utm_content") || undefined;
        const locale = navigator.language || undefined;
        makeRequest('/api/prepare', 'post', {
            utm_campaign,
            utm_medium,
            utm_content,
            utm_source,
            utm_term,
            locale
        }).then((response) => {
            if (response.data.status) {
                setTimeoutDelay(response.data.result.image_generation_time_limit);

                setAllowedNotifications(response.data.result.allow.notifications === 1)
                setAllowPhotoShow(response.data.result.allow.photo === 1)
                if (response.data.result.block_by_paywall === 0) {
                    setShowPaywall(false);
                    return;
                }
            }
            setShowPaywall(true);
        });
    }, [setShowPaywall, setTimeoutDelay])

    const showError = useCallback((text) => {
        setErrorText(text);
        setIsErrorShown(true);
    }, [])

    const hideError = useCallback((text) => {
        setErrorText('');
        setIsErrorShown(false);
    }, [])

    const setPhoto = useCallback((photoSrc) => {
        setPhotoSrc(photoSrc)
    }, [])

    const value = {
        errorText,
        isErrorShown,
        photoSrc,
        showError,
        hideError,
        setPhoto,
        paywallMethod,
        setPaywallMethod,
        shouldShowPaywall,
        setShowPaywall,
        timeoutDelay,
        setTimeoutDelay,
        allowedPhotoShow,
        allowedNotifications
    }


    return <>
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    </>
}


export const useShowError = () => useContextSelector(AppContext, value => value.showError)
export const useHideError = () => useContextSelector(AppContext, value => value.hideError)
export const useErrorText = () => useContextSelector(AppContext, value => value.errorText)
export const useErrorShown = () => useContextSelector(AppContext, value => value.isErrorShown)
export const useSetPhoto = () => useContextSelector(AppContext, value => value.setPhoto)
export const usePhoto = () => useContextSelector(AppContext, value => value.photoSrc)
export const useSetPaywallMethod = () => useContextSelector(AppContext, value => value.setPaywallMethod)
export const usePaywallMethod = () => useContextSelector(AppContext, value => value.paywallMethod)
export const useShouldShowPaywall = () => useContextSelector(AppContext, value => value.shouldShowPaywall)
export const useSetShowPaywall = () => useContextSelector(AppContext, value => value.setShowPaywall)

export const useTimerDelay = () => useContextSelector(AppContext, value => value.timeoutDelay)
export const useSetTimeoutDelay = () => useContextSelector(AppContext, value => value.setTimeoutDelay)
export const useAllowedPhotoShow = () => useContextSelector(AppContext, value => value.allowedPhotoShow)
export const useAllowedNotifications = () => useContextSelector(AppContext, value => value.allowedNotifications)

export const Reviews = ({ reviews }) => {
    return (
        <div className="self-center max-w-[375px] min-w-[374px] mt-[2px] px-[45px]">
            {reviews.map((review) => {
                return (<Review key={review.review + review.title} review={review} />)
            })}
        </div>
    )
}


export const Review = ({ review }) => {
    const { avatar, title, subtitle, review: reviewText } = review
    return (
        <div className="mt-[28px]">
            <div className="flex justify-between align-baseline">
                <div className="flex">
                    <img src={avatar} className="w-[68px] h-[68px] rounded-[50%]" alt="" />
                    <div className="ml-[14px] mt-[3px] self-center">
                        <div className="leading-[normal] font-text font-[500] text-sm text-[#242a46]">
                            {title}
                        </div>
                        <div className="leading-[normal] font-text font-[500] text-sm  text-[#acb8c2] mt-2" >
                            {subtitle}
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-2
                text-sm
                font-text
                rounded-[8px]
                relative
                p-[10px_9px_13px_19px]
              bg-white 
                shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]

                text-[#1b223a]
                leading-[1.29]
              ">
                {reviewText}
            </div>

        </div >
    )
}


import { useLayoutEffect, useRef } from "react";
import { Speedometer, SpeedometerCursor, SBGHot, SBGStunning, SBGAttractive, SBGCute, SBGAverage, SBGUnique } from "../icons"
import '../styles/Animation.css'

const minValue = 0;
const maxValue = 50;

export const SpeedometerCombined = ({ value = 90, onlyAnimation }) => {

    const cursorRef = useRef()

    useLayoutEffect(() => {
        if (cursorRef.current && value) {
            cursorRef.current.style.transform = `rotate(-90deg)`
            let resultValue = value
            let newReversed = false
            if (resultValue <= 50) {
                resultValue = 50 - value;
                newReversed = true
            } else {
                resultValue = value - 50
            }
            const newAngle = ((resultValue - minValue) / (maxValue - minValue)) * 90;
            setTimeout(() => {
                cursorRef.current.style.transform = `rotate(${newReversed ? -newAngle : newAngle}deg)`
            }, 300)
        }
    }, [value])



    let stylesCalculated = { transformOrigin: '50% 88%' };
    if (!onlyAnimation) {
        stylesCalculated = {
            ...stylesCalculated,
            transitionDuration: '0.5s',
            transitionProperty: 'transform'
        }
    }


    return <div className="w-[250px] h-[120px] flex items-center justify-center">
        <div className="relative h-[120px]">
            <Speedometer className="ml-[-18px] relative z-[1]" />
            <SpeedometerCursor
                ref={cursorRef}
                style={stylesCalculated}
                className={`z-[3] absolute left-[calc(50%-10.5px)] bottom-[0px] ${onlyAnimation ? 'infiniteAnimated' : ''}`}
            />
            <div className="absolute z-[2] top-[20px] left-[23.7px]">
                {! onlyAnimation && renderBG(value)}
            </div>
        </div>

    </div>
}


const renderBG = (value) => {
    if (value > 84) {
        return <SBGHot />
    }
    if (value > 67) {
        return <SBGStunning />
    }
    if (value > 50) {
        return <SBGAttractive />
    }
    if (value > 33) {
        return <SBGCute />
    }
    if (value > 16) {
        return <SBGAverage />
    }
    return <SBGUnique />
}

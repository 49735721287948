
import './App.css';

import { ResultPage } from './pages/ResultPage'

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { MainPage } from './pages/MainPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { ErrorPage } from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import { AppContextProvider } from './context';
import { PaywallPage } from './pages/PaywallPage';

function App() {

    return (
        <AppContextProvider >
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/err">
                        <ErrorPage />
                    </Route>

                    <Route path="/ter">
                        <TermsPage />
                    </Route>

                    <Route path="/pri">
                        <PrivacyPage />
                    </Route>

                    <Route path="/pay/:photoId">
                        <PaywallPage />
                    </Route>

                    <Route path="/:photoId">
                        <ResultPage />
                    </Route>
                    <Route path="/">
                        <MainPage />
                    </Route>

                </Switch>
            </Router>
        </AppContextProvider>

    );
}

export default App;

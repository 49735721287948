import Lottie from 'react-lottie';
import * as animationData from '../animations/loading.json'

export const Loader = ({progress}) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className='flex flex-col items-center justify-center mt-[20px] h-[95vh] w-[98vw] absolute top-0 left-0'>
            <Lottie options={defaultOptions}
                height={250}
                width={250}
            />
            <div className=' h-[24px] text-[18px] font-header font-bold color-[#232948] text-center'>{progress}</div>

        </div>
    )
}
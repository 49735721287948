
import { useCallback, useEffect, useState } from 'react';
import usePhotoLoader from '../hooks/usePhotoLoader';

import { useHistory } from "react-router-dom";
import { Loader } from '../components/Loader';
import logoSrc from '../icons/logo.webp';
import resultSrc from '../images/result-example.webp';
import vibeExampleSrc from '../images/vibe-example.webp';
import personSrc from '../images/person-example.webp';
import kardSrc from '../images/kim-kardashian@3x.webp';
import lilSrc from '../images/lil-nas-x@3x.webp';
import zendSrc from '../images/zendaya@3x.webp';
import momoaSrc from '../images/jason-momoa@3x.webp';
import anistonSrc from '../images/jennifer-aniston@3x.webp';
import chalsrc from '../images/timothee-chalamet@3x.webp';
import iosSrc from '../images/app-store@3x.webp';
import androidSrc from '../images/google-play@3x.webp';
import { IconGallery } from '../icons';
import { Link } from 'react-router-dom';
import { useSetPaywallMethod, useSetPhoto, useSetShowPaywall, useSetTimeoutDelay, useShouldShowPaywall, useShowError } from '../context';
import { makeRequest } from '../utils/request';
import SimpleCarousel from '../components/Carousel';
import { Dots } from '../components/Carousel/Dots';
import { isAndroid, isTelegram, waitFor } from '../utils/index';

export function MainPage() {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const { openFileSelector, photoBlob } = usePhotoLoader()
    const [currentIndex, setCurrentIndex] = useState(0);
    const shouldShowPaywall = useShouldShowPaywall();
    const showError = useShowError();
    const setPhotoSrc = useSetPhoto()
    const setPaywallMethod = useSetPaywallMethod();
    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff');
        document.querySelector('body').style = 'background-color: #ffffff'
        setPaywallMethod('none');
    }, [setPaywallMethod])


    useEffect(() => {
        if (isTelegram()) {
            const routeId = window.__tgAppRedirectId;
            if (routeId) {
                window.__tgAppRedirectId = '';
                history.push(`/${routeId}`);
            }
        }
    });

    useEffect(() => {
        const getPhotoStatus = async (photoBlob) => {

            try {
                setIsLoading(true)
                const formData = new FormData();
                await waitFor(500);
                document.querySelector('meta[name="theme-color"]').setAttribute('content', '#f6f7f8');
                document.querySelector('body').style = 'background-color: #f6f7f8'
                formData.append('file', photoBlob)
                const result = await makeRequest(`/api/photo/create`, 'post', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (result.data.status && result.data.result.moderation === 1) {
                    const { photo_id, photo } = result.data.result;
                    setIsLoading(false);
                    setPhotoSrc(photo)
                    if (shouldShowPaywall) {
                        history.push(`/pay/${photo_id}`);
                    } else {
                        history.push(`/${photo_id}`);
                    }


                } else {
                    showError(result.data.result.message || 'No Internet Connection');
                    history.push('/err');
                    setIsLoading(false);
                }
            } catch (e) {
                console.error('e', e);
                history.push('/err');
                setIsLoading(false)
            }
        }

        if (photoBlob) {
            getPhotoStatus(photoBlob)
        }
    }, [photoBlob, history, setPhotoSrc, showError, shouldShowPaywall])

    const openWebcam = useCallback(() => {

        window.dataLayer.push({ 'event': 'take_a_selfie' });
        openFileSelector('webcam')
    }, [openFileSelector])

    const openGalleryForPhotos = useCallback(() => {
        window.dataLayer.push({ 'event': 'upload_photo' });
        openFileSelector('none')
    }, [openFileSelector])


    const clickAppStore = useCallback(() => {
        makeRequest('/api/user/update', 'post', {
            tap: 'ios'
        });
    }, []);

    const clickGooglePlay = useCallback(() => {
        makeRequest('/api/user/update', 'post', {
            tap: 'android'
        });
    }, []);

    return (
        <div className='flex flex-col items-center relative justify-center py-[38px] px-[20px]'>
            {isLoading ? <Loader /> : <>
                <div className='relative z-3 rounded-[48px] bg-[#811883] h-[488px] max-w-[335px] w-[335px] flex flex-col justify-center items-center'>
                    <img src={logoSrc} alt="" className='w-[185px] h-[187px]' />
                    <SimpleCarousel onChangeCurrentIndex={setCurrentIndex} >
                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white  mt-[50px] font-['Montserrat'] select-none`}>
                            See How You
                            <br />
                            Rate with AI
                        </div>
                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white  mt-[50px] font-['Montserrat'] select-none`}>
                            Get Personalized
                            <br />
                            Beauty Tips
                        </div>
                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white  mt-[50px] font-['Montserrat'] select-none`}>
                            Find Your
                            <br />
                            Perfect Match
                        </div>

                    </SimpleCarousel>

                    <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[60px] bg-[rgba(129,24,131,0.5)]'></div>
                    <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[60px] bg-[rgba(129,24,131,0.3)]'></div>
                    <div className='absolute z-4 bottom-[20px] w-full flex justify-center'>
                        <div className='w-[36px] flex justify-between items-center '>
                            <Dots amount={3} selectedIndex={currentIndex} />
                        </div>
                    </div>

                </div>

                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full flex flex-col py-[20px]  items-center'>
                    <div className='leading-[1.33] text-center font-bold font-header flex self-center  text-[#232948] text-[18px]'>
                        Upload Your
                        <br />
                        Front-Facing Selfie
                    </div>
                    <img src={personSrc} alt="" className='mt-[23px] h-[325px] w-[241px]' />

                    <div className='mt-[20px] flex'>
                        {(isAndroid() && isTelegram()) ?
                            <button onClick={openGalleryForPhotos} className='flex  font-bold  w-[241px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                Upload a Selfie
                            </button>
                            :
                            <>
                                <button onClick={openGalleryForPhotos} className='md:hidden font-bold  w-[64px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[50%]'>
                                    <IconGallery />
                                </button>

                                <button onClick={openWebcam} className='md:hidden font-bold ml-[10px]  w-[161px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                    Take a Selfie
                                </button>

                                <button onClick={openGalleryForPhotos} className='md:flex hidden font-bold  w-[241px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                    Upload a Selfie
                                </button>
                            </>
                        }

                    </div>
                </div>

                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col py-[20px]'>
                    <div className=' leading-[1.33] font-header text-center font-bold flex self-center text-[#232948] text-[18px]'>
                        Get Your
                        <br />
                        Score from AI
                    </div>

                    <img src={resultSrc} alt="" className='mt-[18px] h-[278px] w-[241px]' />
                </div>

                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col py-[20px]'>
                    <div className=' leading-[1.33] font-header text-center font-bold flex self-center text-[#232948] text-[18px]'>
                        Discover Your
                        <br />
                        Hidden Vibes
                    </div>
                    <img src={vibeExampleSrc} alt="" className='mt-[18px] h-[278px] w-[241px]' />
                </div>

                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center  flex flex-col py-[20px] px-[62px]'>
                    <div className=' leading-[1.33] text-center font-bold font-header flex self-center  text-[#232948] text-[18px]'>
                        Get Reviews
                        <br />
                        from AI Celebrities
                    </div>
                    <div className='mt-[21px] flex'>
                        <img src={kardSrc} alt="" className='w-[64px] h-[64px]' />
                        <img src={lilSrc} alt="" className='ml-[10px] w-[64px] h-[64px]' />
                        <img src={zendSrc} alt="" className='ml-[9px] w-[64px] h-[64px]' />

                    </div>

                    <div className='mt-[20px] flex'>
                        <img src={momoaSrc} alt="" className='w-[64px] h-[64px]' />
                        <img src={anistonSrc} alt="" className='ml-[10px] w-[64px] h-[64px]' />
                        <img src={chalsrc} alt="" className='ml-[9px] w-[64px] h-[64px]' />
                    </div>
                </div>

                <div className='mt-[30px] flex justify-center gap-[9px] self-center'>
                    <a target='_blank' rel="noreferrer noopener" onClick={clickAppStore} href="https://apps.apple.com/us/app/yasss-anonymous-q-a/id6446479657">
                        <img src={iosSrc} className='w-[120px] h-[41px]' alt="" />
                    </a>

                    <a target='_blank' rel="noreferrer noopener" onClick={clickGooglePlay} href="https://play.google.com/store/apps/details?id=link.yasss.app&hl=en ">
                        <img src={androidSrc} className='w-[120px] h-[41px]' alt="" />
                    </a>
                </div>

                <div className='max-w-[335px] w-full flex justify-center mt-[23px] border-solid border-t border-[#dde0eb] pt-[23px]'>
                    <Link to="/pri" className="font-header text-[13px] text-[#a4a6b2]">Privacy Policy</Link>
                    <Link to="/ter" className=" text-[#a4a6b2] font-header text-[13px] ml-[10px] pl-[10px] border-solid border-l border-[#dde0eb]">Terms of Service</Link>
                </div>

            </>}
        </div>
    );
}
import axios from "axios";
import { makeRequest } from "./request";

export const getColorByScore = (score) => {
    if (score < 17) {
        return { bg: '#30ad43', text: '#ffffff' };
    }
    if (score < 34) {
        return { bg: '#84bd32', text: '#000000' };
    }
    if (score < 51) {
        return { bg: '#d1d80f', text: '#000000' };
    }
    if (score < 68) {
        return { bg: '#fee114', text: '#000000' };
    }
    if (score < 85) {
        return { bg: '#ff8888', text: '#000000' };
    }

    return { bg: '#ff5656', text: '#ffffff' };
}



export const loadResultScore = async (photoId, paywallMethod, onChangeProgress) => {
    let loadedResult = false
    while (!loadedResult) {
        const response = await makeRequest(`/api/photo/status`, 'post', {
            photo_id: photoId,
            paywall: paywallMethod === 'none' ? undefined : paywallMethod,
        });
        if (response.data.status && response.data.result.status === 1) {
            loadedResult = true;
        } else if (response.data.status === false && response.data.message) {
            return response;
        } else {
            onChangeProgress?.(response.data.result.progress);

            await waitFor(1000);
        }
    }

    const respResult = await makeRequest(`/api/photo/result`, 'post', {
        photo_id: photoId,
        paywall: paywallMethod === 'none' ? undefined : paywallMethod,
    });

    return respResult

}


export const loadCharacter = async (photoId, onChangeProgress, onTimeoutExtended, timeoutNumber) => {
    let loadedResult = false;

    setTimeout(() => {
        if (!loadedResult) {
            loadedResult = true;
            onTimeoutExtended();
        }
    }, timeoutNumber * 1000)

    while (!loadedResult) {
        const response = await makeRequest(`/api/character/status`, 'post', {
            photo_id: photoId
        });

        if (response.data.status && response.data.result.url) {
            const result = await convertImageUrl(response.data.result.url, photoId);
            loadedResult = true;
            return result;
        } else {
            onChangeProgress(response.data.result.progress);
            await waitFor(1000);
        }
    }
}


export const waitFor = (time) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, [time])
    })
}

export const convertImageUrl = async (photoUrl, photo_id) => {
    try {
        const photoResponse = await fetch(photoUrl);
        const photoBlob = await photoResponse.blob();
        const formData = new FormData();
        formData.append('file', photoBlob);
        formData.append('photo_id', photo_id);

        const response = await makeRequest(`/api/character/upload/`, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response;
    } catch (e) {
        throw e;
    }


}

export const isTelegram = () => {
    const keys = Object.keys(window.Telegram.WebView.initParams);
    return keys.length > 0;
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}
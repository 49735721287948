import { useCallback, useMemo, useRef } from "react"
import { SpeedometerCombined } from "../Speedometer"
import { Reviews } from "./Reviews"
import { getColorByScore, isTelegram } from "../../utils"
import { BackIcon, ShareIcon, DownloadIcon, ReloadIcon } from "../../icons"
import { useHistory } from "react-router-dom"
import { useSetPhoto } from "../../context"
import "../../styles/Animation.css"
import * as animationData from '../../animations/loading-character.json';
import Lottie from "react-lottie"
import { SharingBlock } from "./SharingBlock"
import { makeRequest } from "../../utils/request"
import { ExternalReviews } from "./ExternalReviews"

export const Result = ({ photoSrc, isAuthorSession, score, status, reviews, characterCaption, isCharacterLoading, characterUrl, characterProgress, timeoutFallenFetch, photoId }) => {

    const renderedResult = useRef()

    const history = useHistory();
    const setPhoto = useSetPhoto();

    const colorOfScore = getColorByScore(score)


    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        className: "self-center",
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const goToMain = useCallback(() => {
        history.push('/')
        setPhoto('')
    }, [history, setPhoto])

    const onShare = useCallback(async () => {
        window.dataLayer.push({ 'event': 'share' });

        makeRequest('/api/user/update', 'post', {
            tap: 'share'
        });

        if (isTelegram()) {
            const a = document.createElement("a");
            a.href = `https://t.me/share/url?url=${encodeURI('t.me/yasss_app_bot/yasss')}?startapp=${photoId}&text=${'Check Out How the AI Rates Me!'}`;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            return;
        }

        if (!navigator.canShare) {
            return;
        }
        const url = window.location.href;
        const shareData = {
            title: "YASSS: AI Face Score",
            text: "See How You Rate with AI",
            url,
        };
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error('failed sharing:', err);
        }
    }, [photoId])

    const downloadLinkPromise = useMemo(async () => {
        if (characterUrl) {
            try {
                const blob = await fetch(characterUrl).then(res => res.blob());
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url
                const name = (new URL(characterUrl)).pathname.replace('/tmp/', '');
                a.download = name;
                return a;
            } catch (e) {
                console.error(e);
            }

        }

    }, [characterUrl])

    const onDownloadImage = useCallback(async () => {
        if (downloadLinkPromise) {
            downloadLinkPromise.then((link) => {
                if (link) {
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
        }
        return;
    }, [downloadLinkPromise])

    const onReload = useCallback(() => {
        if (!characterUrl && timeoutFallenFetch) {
            window.location.reload();
        }

    }, [timeoutFallenFetch, characterUrl]);

    return <div className={`${isTelegram() ? 'bg-[#FFFFFF]' : 'bg-[#f6f7f8]'}  w-full flex flex-col pb-[70px] overflow-hidden items-center`}>
        <div className="flex sm:mt-[42px] mt-[20px] w-[285px] justify-between">

            <button onClick={goToMain} className="bg-[#b6b9c2] rounded-[50%] flex justify-center items-center w-[38px] h-[38px]">
                <a href="/"><BackIcon /></a>
            </button>

            <button onClick={onShare} className={`bg-[#b6b9c2] items-center justify-center rounded-[50%] flex w-[38px] h-[38px]`}>
                <ShareIcon />
            </button>


        </div>

        <div ref={renderedResult} className="relative z-10 h-[430px] flex flex-col pt-[55px] w-full rounded-[50px] bg-[#242a46] mt-[40px] max-w-[335px]">
            <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[60px] bg-[rgba(36,42,70,0.5)]'></div>
            <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[60px] bg-[rgba(36,42,70,0.3)]'></div>

            <div
                style={{ backgroundImage: `url("${photoSrc}")` }}
                className="self-center bg-cover border border-solid font-[15px] border-[white] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
            />

            <div className="flex justify-center mt-6">
                <SpeedometerCombined value={score || 50} />
            </div>

            <div className="flex justify-center mt-[22px]">
                {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                    {status}
                </div>)}
            </div>
        </div>

        {(!isTelegram() && isAuthorSession) && <SharingBlock characterUrl={characterUrl} />}

        <div className="max-w-[260px] text-[#242a46] mt-[50px] text-base leading-[normal] font-bold flex justify-center text-center">
            {characterCaption}
        </div>

        <div onClick={onReload} className="mt-[30px] w-[290px] relative h-[290px] rounded-[16px] bg-[rgba(36,42,70,0.1)] flex justify-center overflow-hidden" >
            {
                (isCharacterLoading && !characterUrl && !timeoutFallenFetch) && <div className="self-center w-[160px] relative left-[-2px] top-[14px]" >
                    <Lottie options={defaultLottieOptions}
                        height={90}
                        width={160}
                    />
                    <div className="h-[24px] text-[18px] mt-[4px] font-header font-bold color-[#000000] text-center">{characterProgress}</div>
                </div>
            }
            {
                (!isCharacterLoading && characterUrl) && <>

                    <button onClick={onDownloadImage} className={`bg-[rgba(0,0,0,0.3)] items-center justify-center rounded-[50%] flex w-[44px] h-[44px] absolute top-[10px] right-[10px]`}>
                        <DownloadIcon />
                    </button>
                    <img src={characterUrl} className="w-full h-full" alt="" />
                </>
            }
            {
                (!characterUrl && timeoutFallenFetch) && <div className="self-center cursor-pointer mt-[23px]" >
                    <ReloadIcon />
                    <div className="text-[18px] mt-[19px] font-header font-bold text-[#595a5b] text-center">Reload</div>
                </div>
            }
        </div>

        {isAuthorSession && <ExternalReviews />}

        <div className="text-[#242a46] mt-[50px] text-base font-bold flex justify-center">
            Reviews from AI Celebrities
        </div>
        <Reviews reviews={reviews} />
    </div >
}


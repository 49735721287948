import { useCallback, useState } from "react";
import { useAllowedNotifications, useAllowedPhotoShow } from "../../context"
import { AccessGrantedIcon } from "../../icons";
import { makeRequest } from "../../utils/request";

export const ExternalReviews = () => {

    const allowedPhotoShow = useAllowedPhotoShow();
    const allowedNotificationsBackend = useAllowedNotifications();

    const [allowedPhoto, setAllowedPhoto] = useState(false);
    const [allowedNotifications, setAllowedNotifications] = useState(false);

    const allowedPhotoCombined = allowedPhotoShow || allowedPhoto;
    const allowedNotificationsCombined = allowedNotificationsBackend || allowedNotifications

    const allowPhoto = useCallback(() => {
        if (allowedPhotoCombined) {
            return;
        }
        makeRequest('/api/user/update', 'post', {
            tap: 'allow_photo'
        });
        setAllowedPhoto(true);
    }, [allowedPhotoCombined])

    const allowNotifications = useCallback(() => {
        if (allowedNotificationsCombined) {
            return;
        }
        makeRequest('/api/user/update', 'post', {
            tap: 'allow_notification'
        });
        setAllowedNotifications(true);
    }, [allowedNotificationsCombined]);

    return (
        <div className="w-[290px]">
            <div className="text-[#242a46] mt-[50px] text-base font-bold flex justify-center text-center">
                Do You Want to Get
                <br />
                Reviews from Other Users?
            </div>

            <div className="mt-[21px] pt-[19px] pb-[18px] flex flex-col gap-[10px] justify-center w-full bg-[#fff] rounded-[16px] shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]">
                <div className="text-[18px] font-bold text-center leading-[1.33] tracking-[0.56px]">
                    Step 1: allow us
                    <br />
                    to show your selfie
                </div>

                <button onClick={allowPhoto} className="self-center cursor-pointer text-[#0076ff] border-solid border-[#0076ff] text-[17px] font-bold border-[3px] w-[150px] flex justify-center items-center rounded-[22px] h-[38px]">
                    {allowedPhotoCombined ? <AccessGrantedIcon /> : 'ALLOW'}
                </button>
            </div>

            <div className="mt-[21px] pt-[19px] pb-[18px] flex flex-col gap-[10px] justify-center w-full  bg-[#fff] rounded-[16px] shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]">
                <div className="text-[18px] font-bold text-center leading-[1.33] tracking-[0.56px]">
                    Step 2: allow us
                    <br />
                    to send you notifications
                </div>

                <button onClick={allowNotifications} className="self-center cursor-pointer text-[#0076ff] border-solid border-[#0076ff] text-[17px] font-bold border-[3px] w-[150px] flex justify-center items-center rounded-[22px] h-[38px]">
                    {allowedNotificationsCombined ? <AccessGrantedIcon /> : 'ALLOW'}
                </button>
            </div>
        </div>
    )
}